import { FunctionComponent } from "react";
import {
  useTenantQuery,
  useUser
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Link, Shimmer } from "@bps/fluent-ui";
import { useNavigate } from "react-router-dom";

interface RemoteIdentityProps {
  tenantId: string;
  userTenantId: string;
}
export const RemoteIdentity: FunctionComponent<RemoteIdentityProps> = ({
  tenantId,
  userTenantId
}) => {
  const navigate = useNavigate();
  const tenantQuery = useTenantQuery(tenantId);
  const userQuery = useUser(tenantId, userTenantId);

  return (
    <QueryStateIndicator
      data={{ tenant: tenantQuery.data, user: userQuery.data }}
      isLoading={tenantQuery?.isLoading || userQuery?.isLoading}
      error={tenantQuery?.error ?? userQuery?.error}
      LoadingComponent={<Shimmer />}
    >
      {({ tenant, user }) => {
        return (
          <li>
            {tenant && (
              <Link
                onClick={() => navigate(`/tenants/${tenant.id}/plt-details`)}
              >
                {tenant?.name}
              </Link>
            )}{" "}
            {user && tenant && (
              <Link
                onClick={() =>
                  navigate(`/tenants/${tenant.id}/plt-users/${user?.id}`)
                }
              >
                ({user?.lastName}, {user?.firstName})
              </Link>
            )}
          </li>
        );
      }}
    </QueryStateIndicator>
  );
};
