import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";

export interface RingDevicesAssignedTableFilterValues {
  fieldDeviceId: string;
}

export const RingDevicesAssignedTableFilter = ({
  children
}: PropsWithChildren<any>) => {
  const filterItems: Item<RingDevicesAssignedTableFilterValues>[] = [
    {
      name: "fieldDeviceId",
      type: "searchBox",
      stickItem: true,
      props: {
        placeholder: "Search by FieldDeviceId",
        removeSpecialCharacters: false
      }
    }
  ];

  return (
    <FilterBar<RingDevicesAssignedTableFilterValues>
      items={filterItems}
      initialValues={{
        fieldDeviceId: ""
      }}
    >
      {children}
    </FilterBar>
  );
};
