import { LogEntriesComponent } from "modules/tenants/sections/ops/logging/LogEntriesComponent";

import {
  Heading,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";
import { useFieldDeviceAzureMonitorLogsQuery } from "@libs/api/gateways/user-experience/user-experience.hooks";
import { config } from "@libs/config/config";

interface DeviceAzureLogsProps {
  device: FieldDevice;
}

export const DeviceAzureLogs: React.FC<DeviceAzureLogsProps> = ({ device }) => {
  const theme = useTheme();
  const azureQuery = useFieldDeviceAzureMonitorLogsQuery(
    device.cloudConnectorId,
    device.id
  );
  return (
    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
      <Heading variant="section-heading">Azure Monitor Logs</Heading>
      <MessageBar messageBarType={MessageBarType.warning}>
        You may
        <Link href={config.azureMonitorUrl} target="_blank" rel="noreferrer">
          click here
        </Link>{" "}
        to open the azure monitor for device {device.id}.
      </MessageBar>
      <Stack.Item>
        <QueryStateIndicator<LogEntry[]> {...azureQuery}>
          {data => <LogEntriesComponent logEntries={data} />}
        </QueryStateIndicator>
      </Stack.Item>
    </Stack>
  );
};
