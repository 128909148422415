import { BpIdUserDto } from "@libs/api/gateways/bp-id/bp-id.dtos";

import { UserGroupDialog } from "modules/users/components/UserGroupDialog";
import React, { useState } from "react";

import {
  ActionButton,
  Grid,
  GridItem,
  Heading,
  NativeList,
  NoDataTile,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import {
  useGetUserGroups,
  useUpdateUserGroupsMutation
} from "@libs/api/gateways/bp-id/bp-id.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { CopyableGuid } from "@components/CopyableText";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { RemoteIdentity } from "./RemoteIdentity";

interface BusinessUserCardProps {
  identity: BpIdUserDto;
}

const BPCLOUD_REMOTE_AUTH = "bpcloud-remote-authn";

const getRemoteIdentity = (
  identity: BpIdUserDto
): { tenantId: string; userTenantId: string }[] => {
  return identity.signInIdentities.reduce((acc, item) => {
    if (item.issuer === BPCLOUD_REMOTE_AUTH) {
      const [, , tenantId, userTenantId] = item.issuerAssignedId.split("\\");
      if (tenantId && userTenantId) return [...acc, { tenantId, userTenantId }];
    }
    return acc;
  }, []);
};

export const BusinessUserCard: React.FC<BusinessUserCardProps> = ({
  identity
}) => {
  const [addGroup, setAddGroup] = useState<boolean>(false);
  const theme = useTheme();
  const userGroups = useGetUserGroups(identity.id);

  const { mutateAsync: updateUser, isLoading } = useUpdateUserGroupsMutation();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltBpidUserWrite
  );

  const onUpdate = (
    userId: string,
    addGroupIds: string[],
    removeGroupIds: string[]
  ) => {
    updateUser({ userId, addGroupIds, removeGroupIds });
  };

  const remoteIdentities = getRemoteIdentity(identity);

  return (
    <Grid
      childrenTokens={{
        columnGap: theme.spacing.s1,
        padding: theme.spacing.s1
      }}
      templateColumns="1fr 1fr 1fr"
    >
      <GridItem>
        <Heading labelPaddings>Record:</Heading>
        <Stack
          tokens={{
            childrenGap: theme.spacing.s2
          }}
        >
          <Text>
            {identity.lastName}, {identity.firstName}
          </Text>
          <CopyableGuid short value={identity.id} />
          <Text>{identity.userPrincipalName}</Text>
        </Stack>
      </GridItem>

      <GridItem>
        <Heading labelPaddings>Remote identities:</Heading>
        <Stack>
          {remoteIdentities.map(item => (
            <NativeList key={item.tenantId + item.userTenantId}>
              <RemoteIdentity
                userTenantId={item.userTenantId}
                tenantId={item.tenantId}
              />
            </NativeList>
          ))}
        </Stack>
      </GridItem>

      <GridItem>
        <Heading>Groups:</Heading>
        <QueryStateIndicator
          {...userGroups}
          NoDataNode={
            <NoDataTile
              styles={{ root: { flexGrow: 1 } }}
              textProps={{
                text: "No groups assigned"
              }}
              linkProps={{
                hidden: !hasWritePermission,
                onClick: () => setAddGroup(true),
                text: "Add group"
              }}
              showBoxShadow={false}
            />
          }
        >
          {data => (
            <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
              <Stack
                horizontal
                disableShrink
                wrap
                tokens={{ childrenGap: theme.spacing.s1 }}
              >
                {data?.map(group => (
                  <TextBadge
                    key={group.displayName}
                    badgeColor={TextBadgeColor.lightGrey}
                    badgeSize={TextBadgeSize.small}
                    styles={{ root: { display: "inline-block" } }}
                  >
                    {group.displayName}
                  </TextBadge>
                ))}
              </Stack>
              <ActionButton
                iconProps={{ iconName: "Add" }}
                onClick={() => setAddGroup(true)}
                disabled={!hasWritePermission || isLoading}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              >
                Add group
              </ActionButton>
            </Stack>
          )}
        </QueryStateIndicator>
      </GridItem>

      <UserGroupDialog
        hidden={!addGroup}
        user={identity}
        userGroups={userGroups?.data}
        onUpdate={onUpdate}
        onDismiss={() => setAddGroup(false)}
      />
    </Grid>
  );
};
