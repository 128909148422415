import {
  DetailsRow,
  ScrollablePane,
  ScrollbarVisibility,
  ShimmeredDetailsListProps,
  Stack
} from "@bps/fluent-ui";
import { DateTimeWithDurationSinceTooltip } from "@components/DateTimeWithDurationSinceTooltip";
import { TableGuidDisplay } from "@components/TableGuidDisplay";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { FieldDeviceProperty } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface PropertiesProps extends ShimmeredDetailsListProps {
  items: FieldDeviceProperty[];
}

export const PropertiesTable = ({ items, ...props }: PropertiesProps) => {
  const columns: DataTableColumn<FieldDeviceProperty>[] = [
    {
      key: "propertyType",
      name: "Name",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      isSorted: true,
      sort: true,
      onRender: (property: FieldDeviceProperty) => property.propertyType
    },
    {
      key: "propertyValue",
      name: "Value",
      minWidth: 150,
      isResizable: true,
      onRender: (property: FieldDeviceProperty) => property.propertyValue
    },
    {
      key: "lastObservedUtc",
      name: "Last Observed",
      minWidth: 150,
      onRender: (property: FieldDeviceProperty) => (
        <DateTimeWithDurationSinceTooltip dateUtc={property.lastObservedUtc} />
      )
    },
    {
      key: "prevObservedUtc",
      name: "Previous Observed",
      minWidth: 150,
      onRender: (property: FieldDeviceProperty) => (
        <DateTimeWithDurationSinceTooltip dateUtc={property.prevObservedUtc} />
      )
    },
    {
      key: "lastSourceId",
      name: "Last Source ID",
      minWidth: 130,
      onRender: (property: FieldDeviceProperty) => (
        <TableGuidDisplay id={property.lastSourceId} />
      )
    },
    {
      key: "lastSourceType",
      name: "Last Source Type",
      onRender: (property: FieldDeviceProperty) => property.lastSourceType
    },
    {
      key: "observationCount",
      name: "Observation Count",
      onRender: (property: FieldDeviceProperty) => property.observationCount
    }
  ];

  return (
    <Stack grow styles={{ root: { position: "relative" } }} verticalFill>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DataTable
          stickyHeader
          isSelectedOnFocus
          items={items}
          columns={columns}
          onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
          {...props}
        />
      </ScrollablePane>
    </Stack>
  );
};
