import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Heading,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  FieldDevice,
  SoftwarePackageLogArgs
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useDeviceLogs } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { getPagesResult } from "@libs/paging/paging.utils";

import { DeviceOnsiteLogFilter } from "./on-site-logs/DeviceOnsiteLogFilter";
import { DeviceOnsiteLogTable } from "./on-site-logs/DeviceOnsiteLogTable";

interface DeviceOnsiteLogsProps {
  device: FieldDevice;
}

export const DeviceOnsiteLogs: React.FC<DeviceOnsiteLogsProps> = ({
  device
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [filtersArgs, setFilterArgs] = useState<SoftwarePackageLogArgs>({
    fieldDeviceId: device.id
  });

  const {
    data: deviceLogsData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useDeviceLogs(filtersArgs);

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      return void (await fetchNextPage());
    }
  };

  const deviceLogs = getPagesResult(deviceLogsData);

  return (
    <Stack
      grow
      styles={{
        root: {
          overflow: "hidden",
          height: "100%",
          background: theme.semanticColors.bodyBackground
        }
      }}
      tokens={{ childrenGap: theme.spacing.s1 }}
    >
      <Heading variant="section-heading">On-Site Device Logs</Heading>
      <MessageBar messageBarType={MessageBarType.warning}>
        To request additional onsite logs, please send an{" "}
        <Link
          onClick={() =>
            navigate(
              `/field-mgmt/${device.id}/services/BestPracticeFieldController/DeviceLogsUploadCommand`
            )
          }
        >
          upload device logs command
        </Link>{" "}
        to the device.
      </MessageBar>
      <DeviceOnsiteLogFilter setFilterArgs={setFilterArgs} device={device} />

      {deviceLogs.length === 0 && !isLoading ? (
        <Stack horizontalAlign="center">
          <Text variant="small">
            Your search returned no results, please refine your search term.
          </Text>
        </Stack>
      ) : (
        <DeviceOnsiteLogTable
          deviceLogs={deviceLogs ?? []}
          hasNextPage={hasNextPage}
          handleScrolledToBottom={handleScrolledToBottom}
          error={error ?? undefined}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};
