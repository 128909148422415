import { useQueryClient } from "react-query";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  DefaultButton,
  Heading,
  IconButton,
  IModalProps,
  Modal,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  FieldDevice,
  FieldDeviceConfiguration
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  FieldOpsCacheKeys,
  useFieldDeviceConfiguration
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

interface DesiredConfigModalProps extends IModalProps {
  show: boolean;
  device: FieldDevice;
  onDismiss: () => void;
}

export const DesiredConfigModal: React.FC<DesiredConfigModalProps> = ({
  device,
  show,
  onDismiss,
  ...props
}) => {
  const fieldDeviceDesiredConfigQuery = useFieldDeviceConfiguration(device.id);

  const queryClient = useQueryClient();
  const invalidateQueryCache = async () => {
    await queryClient.invalidateQueries([
      FieldOpsCacheKeys.FieldDeviceDesiredConfig
    ]);
  };

  const theme = useTheme();
  return (
    <Modal
      isOpen={show}
      {...props}
      styles={{ scrollableContent: { maxHeight: "none" } }}
    >
      <Stack
        verticalFill
        styles={{
          root: { padding: theme.spacing.l1, maxHeight: 800 }
        }}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Heading variant="modal-heading">
            Desired Field Configuration Preview
          </Heading>
          <IconButton onClick={onDismiss} iconProps={{ iconName: "Cancel" }} />
        </Stack>

        <Stack
          verticalFill
          styles={{
            root: { maxHeight: 600, overflow: "auto", maxWidth: 1200 }
          }}
        >
          <QueryStateIndicator<FieldDeviceConfiguration>
            {...fieldDeviceDesiredConfigQuery}
          >
            {json => (
              <SyntaxHighlighter
                language="json"
                // eslint-disable-next-line react/forbid-component-props
                style={a11yDark}
                wrapLongLines={true}
                wrapLines={true}
              >
                {JSON.stringify(json, null, 2)}
              </SyntaxHighlighter>
            )}
          </QueryStateIndicator>
        </Stack>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <DefaultButton onClick={invalidateQueryCache}>Reload</DefaultButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
