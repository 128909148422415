import { ShimmeredDetailsListProps, Stack } from "@bps/fluent-ui";
import { DateTimeWithDurationSinceTooltip } from "@components/DateTimeWithDurationSinceTooltip";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { FieldDeviceClaim } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface ClaimsProps extends ShimmeredDetailsListProps {
  items: FieldDeviceClaim[];
}

export const ClaimsTable = ({ items, ...props }: ClaimsProps) => {
  const columns: DataTableColumn<FieldDeviceClaim>[] = [
    {
      key: "key",
      name: "Key",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      isSorted: true,
      sort: true,
      onRender: (claim: FieldDeviceClaim) => claim.key
    },
    {
      key: "value",
      name: "Value",
      minWidth: 150,
      isResizable: true,
      onRender: (claim: FieldDeviceClaim) => claim.value
    },
    {
      key: "createdDate",
      name: "Created Date",
      minWidth: 150,
      onRender: (claim: FieldDeviceClaim) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={claim.changeLog?.createdDate}
        />
      )
    },
    {
      key: "createdBy",
      name: "Created By",
      minWidth: 150,
      onRender: (claim: FieldDeviceClaim) => claim.changeLog?.createdBy
    },
    {
      key: "updatedDate",
      name: "Last Updated Date",
      minWidth: 150,
      onRender: (claim: FieldDeviceClaim) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={claim.changeLog?.updatedDate}
        />
      )
    },
    {
      key: "updatedBy",
      name: "Last Updated By",
      minWidth: 150,
      onRender: (claim: FieldDeviceClaim) => claim.changeLog?.updatedBy
    }
  ];

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          overflowY: "auto"
        }
      }}
    >
      <DataTable
        stickyHeader
        shimmerLines={10}
        items={items}
        columns={columns}
        onShouldVirtualize={() => true}
        {...props}
      />
    </Stack>
  );
};
