import { ComponentDetailsTabs } from "modules/system/sections/plt/components-definitions/components/components-definitions-details/ComponentsDefinitionsExpandedRow.types";
import { useState } from "react";

import { Pivot, PivotItem } from "@bps/fluent-ui";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { DeviceAzureLogs } from "./logSections/DeviceAzureLogs";
import { DeviceOnsiteLogs } from "./logSections/DeviceOnsiteLogs";
import { DeviceSeqLogs } from "./logSections/DeviceSeqLogs";

interface DeviceLogTabsProps {
  device: FieldDevice;
}

export const DeviceLogTabs: React.FC<DeviceLogTabsProps> = ({ device }) => {
  const [selectedKey, setSelectedKey] = useState<
    ComponentDetailsTabs | undefined
  >(ComponentDetailsTabs.Dependencies);

  return (
    <>
      <Pivot
        selectedKey={selectedKey}
        styles={{
          itemContainer: { padding: 8, position: "relative" }
        }}
        onLinkClick={item => {
          setSelectedKey(item?.props.itemKey as ComponentDetailsTabs);
        }}
      >
        <PivotItem
          itemKey={ComponentDetailsTabs.Dependencies}
          headerText="On-site Logs"
        >
          <DeviceOnsiteLogs device={device} />
        </PivotItem>

        <PivotItem
          itemKey={ComponentDetailsTabs.Implementations}
          headerText="SEQ Logs"
        >
          <DeviceSeqLogs device={device} />
        </PivotItem>

        <PivotItem
          itemKey={ComponentDetailsTabs.Settings}
          headerText="Azure Monitor Logs"
        >
          <DeviceAzureLogs device={device} />
        </PivotItem>
      </Pivot>
    </>
  );
};
