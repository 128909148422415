import React from "react";

import { SectionTitle } from "@components/SectionTitle";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { DeviceLogTabs } from "./DeviceLogTabs";

interface FieldDeviceSecurityProps {
  device: FieldDevice;
}

export const FieldDeviceLogs: React.FC<FieldDeviceSecurityProps> = ({
  device
}) => {
  return (
    <>
      <SectionTitle>Field Device Logs</SectionTitle>
      <DeviceLogTabs device={device} />
    </>
  );
};
