import React from "react";

import { Heading } from "@bps/fluent-ui";
import {
  FieldDevice,
  FieldDeviceClaim
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useFieldDeviceClaims } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { ClaimsFilter, ClaimsFilterValues } from "./ClaimsFilter";
import { ClaimsTable } from "./ClaimsTable";

interface FieldDeviceClaimsProps {
  device: FieldDevice;
}

export const FieldDeviceClaims: React.FC<FieldDeviceClaimsProps> = ({
  device
}) => {
  const claimsQuery = useFieldDeviceClaims(device.id);
  const { data, isLoading, error } = claimsQuery;
  const errorMessage = error?.message;

  return (
    <>
      <Heading variant="section-heading">Claims</Heading>

      <ClaimsFilter>
        {({ values }) => {
          return (
            <ClaimsTable
              items={filterAndSortClaims(data, values)}
              enableShimmer={isLoading}
              errorMessage={errorMessage}
            />
          );
        }}
      </ClaimsFilter>
    </>
  );
};

const filterAndSortClaims = (
  claims: FieldDeviceClaim[] | undefined,
  filter: ClaimsFilterValues
): FieldDeviceClaim[] => {
  if (!claims) return [];

  return claims
    .filter(
      d =>
        filter.name === "" ||
        d.key.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0 ||
        (d.value &&
          d.value.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0)
    )
    .sort((a: FieldDeviceClaim, b: FieldDeviceClaim) => {
      return a.key < b.key ? -1 : 1;
    });
};
