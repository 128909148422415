import { DangerButton } from "@components/buttons/DangerButton";
import { YesOrNoIcon } from "@components/YesOrNoIcon";

import { BhaDependantDialog } from "modules/users/BhaProfile/BhaDependantDialog";
import { BhaDevicesDialog } from "modules/users/BhaProfile/BhaDevicesDialog";
import { ConsumerUserCard } from "modules/users/BhaProfile/BhaIdentityCard";
import { DeleteUserDialog } from "modules/users/components/DeleteUserDialog";
import React, { useState } from "react";

import { Link, Stack, Text, useTheme } from "@bps/fluent-ui";

import { ConsumerUserProfile } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";
import {
  useConsumerUser,
  useDeleteUserMutation
} from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";

interface BhaProfileListExpandProps {
  profile: ConsumerUserProfile;
}

export const BhaProfileListExpand: React.FC<BhaProfileListExpandProps> = ({
  profile
}) => {
  const theme = useTheme();

  const deleteMutation = useDeleteUserMutation();
  const [deleteUser, setDeleteUser] = useState<boolean>(false);
  const [showDevices, setShowDevices] = useState<boolean>(false);
  const [showDependants, setShowDependants] = useState<boolean>(false);
  const { data: identity, isFetching, error, refetch } = useConsumerUser(
    profile.id
  );

  return (
    <>
      <DeleteUserDialog
        hidden={!deleteUser}
        onCancel={() => setDeleteUser(false)}
        firstname={profile.firstName}
        surname={profile.lastName}
        onDelete={() => deleteMutation.mutateAsync(profile.id)}
      />
      <BhaDevicesDialog
        hidden={!showDevices}
        onDismiss={() => setShowDevices(false)}
        profileId={profile.id}
      />
      <BhaDependantDialog
        hidden={!showDependants}
        onDismiss={() => setShowDependants(false)}
        profileId={profile.id}
      />
      <Stack
        tokens={{
          childrenGap: theme.spacing.m
        }}
        styles={{
          root: {
            padding: theme.spacing.l1
          }
        }}
      >
        <Stack
          horizontal
          tokens={{
            childrenGap: "10%"
          }}
          horizontalAlign="space-between"
        >
          <Stack
            tokens={{
              childrenGap: theme.spacing.m
            }}
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            <Stack
              horizontal
              tokens={{
                childrenGap: theme.spacing.m
              }}
              verticalAlign="start"
              horizontalAlign="space-between"
            >
              <Stack>
                <Text>{profile.street1 || "Unknown Street"}</Text>
                <Text>{profile.city || "Unknown City"}</Text>
                <Text>{profile.postCode || "Unknown Postcode"}</Text>
              </Stack>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                  childrenGap: theme.spacing.m
                }}
              >
                <Text>Marketing Consent</Text>
                <YesOrNoIcon when={profile.receiveMarketingMaterial} />
              </Stack>
            </Stack>
            <Stack
              horizontal
              tokens={{
                childrenGap: theme.spacing.m
              }}
            >
              <Link
                onClick={() => {
                  setShowDependants(true);
                }}
              >
                Dependants ({profile.familyMemberCount || 0})
              </Link>
              <Link
                onClick={() => {
                  setShowDevices(true);
                }}
              >
                Devices ({profile.deviceCount || 0})
              </Link>
            </Stack>
          </Stack>
          <Stack
            styles={{
              root: {
                width: "50%",
                flex: 1,
                borderWidth: 1,
                borderColor: theme.palette.neutralTertiaryAlt,
                borderStyle: "solid",
                padding: theme.spacing.s1
              }
            }}
          >
            <ConsumerUserCard
              identity={identity}
              refetch={refetch}
              isLoading={isFetching}
              error={error}
            />
          </Stack>
        </Stack>
        <Stack horizontal horizontalAlign="end">
          <DangerButton onClick={() => setDeleteUser(true)}>
            Delete user
          </DangerButton>
        </Stack>
      </Stack>
    </>
  );
};
