import React, { useMemo } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";

interface FieldDeviceStatusProps {
  status?: string;
}

export const FieldDeviceStatus: React.FC<FieldDeviceStatusProps> = ({
  status
}) => {
  const statusType = useMemo((): MessageBarType => {
    let type = MessageBarType.info;
    switch (status) {
      case undefined:
        type = MessageBarType.error;
        break;
      case "OK":
      case "Running":
        type = MessageBarType.success;
        break;
      case "Healthy":
        type = MessageBarType.success;
        break;
    }
    return type;
  }, [status]);

  return (
    <MessageBar messageBarType={statusType}>{status ?? "Stopped"}</MessageBar>
  );
};
