import { LogEntriesComponent } from "modules/tenants/sections/ops/logging/LogEntriesComponent";

import {
  Heading,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";
import { useFieldDeviceSeqLogsQuery } from "@libs/api/gateways/user-experience/user-experience.hooks";
import { config } from "@libs/config/config";

interface DeviceSeqLogsProps {
  device: FieldDevice;
}

export const DeviceSeqLogs: React.FC<DeviceSeqLogsProps> = ({ device }) => {
  const theme = useTheme();
  const seqQuery = useFieldDeviceSeqLogsQuery(
    device.cloudConnectorId,
    device.id
  );

  return (
    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
      <Heading variant="section-heading">SEQ Logs</Heading>
      <MessageBar messageBarType={MessageBarType.warning}>
        You may
        <Link
          href={`${config.seqUrl}?filter=BpFieldDeviceId%20%3D%20"${device.id}"%20%7C%7C%20BpCloudConnectorId%20%3D%20"${device.cloudConnectorId}"`}
          target="_blank"
          rel="noreferrer"
        >
          click here
        </Link>{" "}
        to open Seq for device {device.id}
      </MessageBar>
      <Stack.Item>
        <QueryStateIndicator<LogEntry[]> {...seqQuery}>
          {data => <LogEntriesComponent logEntries={data} />}
        </QueryStateIndicator>
      </Stack.Item>
    </Stack>
  );
};
