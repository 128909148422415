import { CopyableGuid } from "@components/CopyableText";
import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { YesOrNoIcon } from "@components/YesOrNoIcon";
import { BpIdUserDto } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { BusinessUserCard } from "./BusinessUserCard";
import React, { useState } from "react";

import {
  IColumn,
  IconButton,
  ScrollablePane,
  ShimmeredDetailsListProps,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { DeleteUserDialog } from "../components/DeleteUserDialog";
import { useDeleteUserMutation } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

const getSignInTypeId = (
  signInIdentities: BpIdUserDto["signInIdentities"],
  signInType: string
) => {
  return (
    signInIdentities?.find(x => x.signInType === signInType)
      ?.issuerAssignedId ?? "-"
  );
};

const DEFAULT_DISPLAY_NAME = "unknown";

export const BusinessUsersTable: React.FC<ShimmeredDetailsListProps> = props => {
  const theme = useTheme();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltBpidUserWrite
  );

  const [userToDelete, setUserToDelete] = useState<BpIdUserDto | undefined>(
    undefined
  );

  const { mutateAsync: deleteUser, isLoading, error } = useDeleteUserMutation();

  const columns: IColumn[] = [
    {
      key: "id",
      name: "Id",
      minWidth: 100,
      maxWidth: 150,
      onRender: ({ id }) => (id ? <CopyableGuid short value={id} /> : "")
    },
    {
      key: "name",
      name: "Name",
      fieldName: "displayName",
      minWidth: 100,
      maxWidth: 350,
      onRender: ({ displayName, firstName, lastName }: BpIdUserDto) =>
        displayName && displayName !== DEFAULT_DISPLAY_NAME
          ? displayName
          : `${firstName} ${lastName}`
    },
    {
      key: "email",
      name: "Email",
      minWidth: 100,
      maxWidth: 300,
      onRender: ({ signInIdentities }) => (
        <Text nowrap>{getSignInTypeId(signInIdentities, "emailAddress")}</Text>
      )
    },
    {
      key: "createdDateTime",
      name: "Created",
      minWidth: 200,
      maxWidth: 200,
      onRender: ({ createdDate }: BpIdUserDto) =>
        createdDate &&
        DateTime.fromISO(createdDate).toFormat(DATE_FORMATS.DETAILED_DATE_TIME)
    },
    {
      key: "accountEnabled",
      name: "Enabled",
      minWidth: 100,
      maxWidth: 100,
      onRender: ({ accountEnabled }) => <YesOrNoIcon when={accountEnabled} />
    },
    {
      key: "actions",
      name: "",
      minWidth: 34,
      maxWidth: 34,
      onRender: (item: BpIdUserDto) =>
        hasWritePermission ? (
          <TooltipHost content="Delete user">
            <IconButton
              iconProps={{ iconName: "Delete" }}
              styles={{ icon: { color: theme.semanticColors.errorIcon } }}
              onClick={event => {
                event.stopPropagation();
                setUserToDelete(item);
              }}
            />
          </TooltipHost>
        ) : null
    }
  ];
  return (
    <>
      <Stack styles={{ root: { position: "relative", height: "100%" } }}>
        <ScrollablePane>
          <ShimmeredDetailsList
            stickyHeader
            columns={columns}
            onRenderRow={row => (
              <DetailsRowExpand
                row={row}
                expand={<BusinessUserCard identity={row?.item} />}
              />
            )}
            {...props}
          />
        </ScrollablePane>
      </Stack>

      {userToDelete && (
        <DeleteUserDialog
          hidden={false}
          firstname={userToDelete.firstName}
          surname={userToDelete.lastName}
          onDelete={() => deleteUser(userToDelete.id)}
          onCancel={() => setUserToDelete(undefined)}
          isDeleting={isLoading}
          error={error?.message}
        />
      )}
    </>
  );
};
