import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";

interface LogEntriesComponentProps {
  logEntries: LogEntry[];
}

export const LogEntriesComponent = ({
  logEntries
}: LogEntriesComponentProps) => {
  if (logEntries?.length === 0) {
    return (
      <MessageBar
        messageBarType={MessageBarType.success}
        styles={{
          content: {
            alignItems: "center"
          }
        }}
      >
        No log entries returned.
      </MessageBar>
    );
  }

  function convertLogEntryLevel(level: string) {
    switch (level) {
      case "0":
        return "Debug";
      case "1":
        return "Information";
      case "2":
        return "Warning";
      case "3":
        return "Error";
      default:
        return level;
    }
  }

  const logs = Object.entries(logEntries).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, logEntry]) =>
      `${DateTime.fromISO(logEntry.logDate).toFormat(
        "f"
      )} ${convertLogEntryLevel(logEntry.level)} (${logEntry.application}) ${
        logEntry.message
      }`
  );

  return (
    <SyntaxHighlighter
      language="accesslog"
      // eslint-disable-next-line react/forbid-component-props
      style={stackoverflowLight}
      wrapLongLines={true}
      wrapLines={true}
    >
      {logs.join("\n")}
    </SyntaxHighlighter>
  );
};
