import { ServiceStatus } from "modules/field-mgmt/tabs/ServiceStatus";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { SectionTitle } from "@components/SectionTitle";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useFieldDevicesDetails } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { DeviceOverviewTable, getOverviewItems } from "./DeviceOverviewTable";

interface FieldDeviceOverviewProps {
  device: FieldDevice;
}

export const FieldDeviceOverview: React.FC<FieldDeviceOverviewProps> = ({
  device
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const now = DateTime.now();
  const hasExpired =
    device.tokenExpiryUtc != null && now >= device.tokenExpiryUtc;

  const { data: deviceDetails, isLoading } = useFieldDevicesDetails(device.id);

  return (
    <>
      <SectionTitle>Device Overview</SectionTitle>

      {device.tokenExpiryUtc === undefined && (
        <MessageBar messageBarType={MessageBarType.error}>
          No token has been issued for this device. Please ensure the device is
          active to initiate a request for an authentication token.
        </MessageBar>
      )}

      {hasExpired && (
        <MessageBar messageBarType={MessageBarType.warning}>
          The latest token has expired. Please visit the{" "}
          <Link
            onClick={() => navigate(`/field-mgmt/${device.id}/fd-security`)}
          >
            device security
          </Link>{" "}
          page, complete any necessary authentication actions and review the
          device secret used for this device.
        </MessageBar>
      )}

      <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
        <DeviceOverviewTable
          items={getOverviewItems(deviceDetails!, device)}
          isLoading={isLoading}
        />

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.l2 }}
          styles={{ root: { display: "flex", margin: theme.spacing.m } }}
        >
          <Stack
            tokens={{ childrenGap: theme.spacing.s1 }}
            styles={{
              root: {
                display: "block",
                justifyContent: "center",
                width: "50%"
              }
            }}
          >
            <Text bold>Controller Service</Text>
            <ServiceStatus
              observedTime={deviceDetails?.controllerLastObservedDateTimeUtc}
              runStatusSid={deviceDetails?.controllerRunStatusSid}
              startTime={deviceDetails?.controllerStartDateTimeUtc}
              startup={deviceDetails?.controllerStartup}
              status={deviceDetails?.controllerStatus}
              version={deviceDetails?.controllerVersion}
            />
          </Stack>
          <Stack
            tokens={{ childrenGap: theme.spacing.s1 }}
            styles={{
              root: {
                display: "block",
                justifyContent: "center",
                width: "50%"
              }
            }}
          >
            <Text bold>Recovery Agent Service</Text>
            <ServiceStatus
              observedTime={deviceDetails?.recoveryAgentLastObservedDateTimeUtc}
              runStatusSid={deviceDetails?.recoveryAgentRunStatusSid}
              startTime={deviceDetails?.recoveryAgentStartDateTimeUtc}
              startup={deviceDetails?.recoveryAgentStartup}
              status={deviceDetails?.recoveryAgentStatus}
              version={deviceDetails?.recoveryAgentVersion}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
