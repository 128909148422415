import { CUProfiles } from "modules/users/BusinessUserProfiles";
import { BusinessUsers } from "./business-users";
import { ConsumerUserIdentities } from "modules/users/ConsumerUserIdentities";
import { PltUsers } from "modules/users/PltUsers";
import { Route, Routes } from "react-router-dom";
import { UsersTabs } from "./users-tabs.constants";
import { DefaultBreadcrumbs } from "@components/breadcrumbs/DefaultBreadcrumbs";

export const UsersRoutes: React.FC = () => (
  <Routes>
    <Route element={<DefaultBreadcrumbs tabs={UsersTabs} />}>
      <Route
        path={UsersTabs.ConsumerUsers.IdentitySearch.id}
        element={<ConsumerUserIdentities />}
      />
      <Route path={UsersTabs.ConsumerUsers.FamilySearch.id} element={null} />
      <Route
        path={UsersTabs.ConsumerUsers.ProfileSearch.id}
        element={<CUProfiles />}
      />
      <Route
        path={UsersTabs.BusinessUsers.IdentitySearch.id}
        element={<BusinessUsers />}
      />
      <Route
        path={UsersTabs.BusinessUsers.UserSearch.id}
        element={<PltUsers />}
      />
    </Route>
  </Routes>
);
