import { FunctionComponent } from "react";
import { FilterBar } from "@components/filter-bar/FilterBar";
import { Item } from "@components/filter-bar/FilterBar.types";
import { Validator } from "@components/form/validation/Validator";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { isGUID } from "@bps/utils";
import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useTheme } from "@bps/fluent-ui";

const validator = new Validator<BpIdUsersSearchArgs>(validator => ({
  email: validator.string().email({
    message: ValidationMessages.email
  }),
  mobile: validator.string().phone({ message: "Numbers only" }),
  objectId: validator.custom().func<"objectId">(value => {
    if (!value?.trim() || isGUID(value)) return value;
    return ValidationMessages.guid;
  })
}));

interface BusinessUsersFilterProps {
  onFilter: (values: BpIdUsersSearchArgs) => void;
  onReset: () => void;
}
export const BusinessUsersFilter: FunctionComponent<BusinessUsersFilterProps> = ({
  onFilter,
  onReset
}) => {
  const theme = useTheme();
  const items: Item<BpIdUsersSearchArgs>[] = [
    {
      type: "searchBox",
      name: "objectId",
      props: { placeholder: "Object Id", removeSpecialCharacters: false },
      stickItem: true
    },
    {
      type: "searchBox",
      name: "givenNamePrefix",
      props: { placeholder: "Given Name", removeSpecialCharacters: false }
    },
    {
      type: "searchBox",
      name: "surnamePrefix",
      props: { placeholder: "Last Name", removeSpecialCharacters: false }
    },
    {
      type: "searchBox",
      name: "email",
      props: { placeholder: "Email", removeSpecialCharacters: false }
    },
    {
      type: "searchBox",
      name: "mobile",
      props: { placeholder: "Phone", removeSpecialCharacters: false }
    },
    {
      type: "searchBox",
      name: "userPrincipalNamePrefix",
      props: {
        placeholder: "User Principal Name",
        removeSpecialCharacters: false
      }
    }
  ];
  return (
    <FilterBar<BpIdUsersSearchArgs>
      items={items}
      applyWithButton
      onFilter={(_actions, values) => onFilter(values)}
      onReset={actions => {
        actions.reset();
        onReset();
      }}
      validate={validator.validate}
      maxItemWidth={190}
      styles={{
        actions: {
          ".ms-TooltipHost:last-child .ms-Button--icon:not(:disabled)": {
            background: theme.palette.neutralLight,
            i: {
              color: theme.palette.themePrimary,
              fontWeight: 600
            }
          }
        }
      }}
    />
  );
};
