import React from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { FieldDeviceActions } from "./actions/FieldDeviceActions";
import { FieldDeviceClaims } from "./claims";
import { FieldDeviceGeneral } from "./general/FieldDeviceGeneral";

interface FieldDeviceSecurityProps {
  device: FieldDevice;
}

export const FieldDeviceSecurity: React.FC<FieldDeviceSecurityProps> = ({
  device
}) => {
  const theme = useTheme();

  return (
    <>
      <SectionTitle>Device Security</SectionTitle>

      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <FieldDeviceGeneral device={device} />
          <FieldDeviceActions device={device} />
        </Stack>

        <FieldDeviceClaims device={device} />
      </Stack>
    </>
  );
};
