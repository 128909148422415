import { generatePath, useNavigate, useParams } from "react-router-dom";

import { CenteredBox, Stack, Text, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { Item } from "@components/SelectionList";
import { withPermissions } from "@components/withPermissions";
import { useDeploymentRings } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { RingList } from "./RingList";
import { DevicesAssigned } from "./rollout/rolloutDevicesAssigned/DevicesAssigned";
import { RolloutList } from "./rollout/RolloutList";
import { DeploymentRingSelectorHost } from "./selector";

const DeployRingsBase: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { ringId } = useParams<{ ringId: string }>();

  const selectRing = (item: Item) => {
    const to = generatePath("/system/plt/deployment-rings/:ringId", {
      ringId: item.id
    });
    navigate(to);
  };

  const deploymentRingsQuery = useDeploymentRings();
  return (
    <>
      <SectionTitle>Deployment Rings</SectionTitle>
      <QueryStateIndicator {...deploymentRingsQuery}>
        {rings => {
          const ring = rings.find(x => x.id === ringId);

          return (
            <Stack
              horizontal
              verticalFill
              tokens={{ childrenGap: theme.spacing.m }}
            >
              <Stack styles={{ root: { width: "300px" } }}>
                <RingList
                  deploymentRings={rings}
                  deploymentRing={ring}
                  onSelectedItemChange={selectRing}
                />
              </Stack>
              <Stack verticalFill styles={{ root: { width: "100%" } }}>
                {ring ? (
                  <Stack
                    verticalFill
                    tokens={{ childrenGap: theme.spacing.s1 }}
                  >
                    <Text variant="xLarge">
                      {ring.displayName} - {ring.code}
                    </Text>
                    <Text>{ring.description}</Text>
                    <Stack
                      tokens={{ childrenGap: theme.spacing.m }}
                      styles={{
                        root: { overflow: "auto", height: "100%" }
                      }}
                    >
                      <DeploymentRingSelectorHost deploymentRing={ring} />

                      <Stack
                        horizontal
                        tokens={{ childrenGap: theme.spacing.m }}
                      >
                        <RolloutList deploymentRingId={ring.id} />
                        <DevicesAssigned deploymentRingId={ring.id} />
                      </Stack>
                    </Stack>
                  </Stack>
                ) : (
                  <CenteredBox>
                    <Text>Please select a deployment ring</Text>
                  </CenteredBox>
                )}
              </Stack>
            </Stack>
          );
        }}
      </QueryStateIndicator>
    </>
  );
};

export const DeployRings = withPermissions(
  DeployRingsBase,
  [Permissions.PltFieldManagementRead, Permissions.PltFieldManagementWrite],
  "or"
);
