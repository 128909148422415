import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  DetailsRow,
  IColumn,
  Link,
  ScrollablePane,
  ShimmeredDetailsListProps
} from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { FieldDeviceDeploymentRingDetail } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface RolloutDevicesAssignedTableProps
  extends ShimmeredDetailsListProps {}

export const RolloutDevicesAssignedTable = ({
  ...props
}: RolloutDevicesAssignedTableProps) => {
  const navigate = useNavigate();
  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "Devices",
        name: "Devices",
        minWidth: 150,
        maxWidth: 800,
        isResizable: true,
        onRender: (dto: FieldDeviceDeploymentRingDetail) => (
          <Link
            onClick={() =>
              navigate(`/field-mgmt/${dto.fieldDeviceId}/fd-overview`)
            }
          >
            {dto.fieldDeviceId}
          </Link>
        )
      }
    ];
  }, [navigate]);

  return (
    <ScrollablePane styles={{ root: { height: 800, position: "relative" } }}>
      <ShimmeredDetailsList
        stickyHeader
        shimmerLines={5}
        columns={columns}
        onShouldVirtualize={() => true}
        onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
        {...props}
      />
    </ScrollablePane>
  );
};
