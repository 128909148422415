import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  ButtonsGroupChoice,
  ButtonsGroupOption,
  DefaultButton,
  Heading,
  IconButton,
  Modal,
  PrimaryButton,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import {
  ChannelPreference,
  DeviceCommand
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface SendCommandDialogProps {
  formData?: DeviceCommand;
  isShown: boolean;
  onDismiss: () => void;
  onDispatch: () => void;
  setFormData: (value: DeviceCommand) => void;
}

export const SendCommandDialog: React.FC<SendCommandDialogProps> = ({
  formData,
  isShown,
  onDismiss,
  onDispatch,
  setFormData
}) => {
  const theme = useTheme();
  const [commandDispatched, setCommandDispatched] = useState(false);
  const handleDispatch = () => {
    setCommandDispatched(true);
    onDispatch();
  };

  // Don't permit more than one command send without first closing the window.
  useEffect(() => {
    if (commandDispatched && !isShown) {
      setCommandDispatched(false);
    }
  }, [commandDispatched, isShown, setCommandDispatched]);

  if (formData === undefined) {
    return null;
  }

  const onChange = value => {
    setFormData({ ...formData, channelPreference: value });
  };

  const options = [
    { key: ChannelPreference.Queued, text: ChannelPreference.Queued },
    { key: ChannelPreference.Realtime, text: ChannelPreference.Realtime },
    {
      key: ChannelPreference.RealtimeWithQueuedFallback,
      text: "Realtime With Queued Fallback"
    }
  ] as ButtonsGroupOption<string>[];

  return (
    <Modal isOpen={isShown}>
      <Stack
        verticalFill
        styles={{
          root: { padding: theme.spacing.l1, overflow: "auto", maxHeight: 800 }
        }}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Heading variant="modal-heading">Send Command</Heading>
          <IconButton onClick={onDismiss} iconProps={{ iconName: "Cancel" }} />
        </Stack>

        <Stack.Item>
          <Heading labelPaddings>Channel Preference</Heading>
          <ButtonsGroupChoice
            options={options}
            onChange={onChange}
            value={formData.channelPreference}
            notUnselectable
          />
        </Stack.Item>

        <SyntaxHighlighter
          language="json"
          // eslint-disable-next-line react/forbid-component-props
          style={a11yDark}
          wrapLongLines={true}
          wrapLines={true}
        >
          {JSON.stringify(formData, null, 2)}
        </SyntaxHighlighter>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <PrimaryButton
            onClick={handleDispatch}
            disabled={!!commandDispatched}
          >
            Send Command
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
