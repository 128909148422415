import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";
import {
  FieldDevice,
  SoftwarePackageLogArgs
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface DevicesOnsiteLogFilterValues {
  searchKeyword: string;
}

interface DeviceOnsiteLogFilterProps extends PropsWithChildren<any> {
  setFilterArgs: React.Dispatch<
    React.SetStateAction<SoftwarePackageLogArgs | undefined>
  >;
  device: FieldDevice;
}

export const DeviceOnsiteLogFilter = ({
  children,
  setFilterArgs,
  device
}: DeviceOnsiteLogFilterProps) => {
  // Filter Options
  const filterOptions: Item<DevicesOnsiteLogFilterValues>[] = [
    {
      type: "searchBox",
      name: "searchKeyword",
      stickItem: true,
      props: {
        placeholder: "Filter by keyword",
        removeSpecialCharacters: false,
        styles: { root: { width: 500 } }
      }
    }
  ];

  return (
    <FilterBar<DevicesOnsiteLogFilterValues>
      items={filterOptions}
      initialValues={{
        searchKeyword: ""
      }}
      onChange={filter => {
        if (
          filter.values.searchKeyword.length >= 4 ||
          filter.values.searchKeyword.length === 0
        ) {
          setFilterArgs({
            searchKeyword: filter.values.searchKeyword,
            fieldDeviceId: device.id
          });
        }
      }}
    >
      {children}
    </FilterBar>
  );
};
