import { dangerButtonStyles } from "@components/buttons/DangerButton";
import React, { useState } from "react";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";

interface DeleteUserDialogProps extends Omit<ConfirmDialogProps, "onConfirm"> {
  firstname: string;
  surname: string;
  onDelete: () => Promise<void>;
  error?: string;
  isDeleting?: boolean;
}

export const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({
  firstname,
  surname,
  onDelete,
  onCancel,
  isDeleting,
  error,
  ...props
}) => {
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>("");

  const fullName = `${firstname} ${surname}`;

  const onConfirm = async () => {
    try {
      await onDelete();
      onCancel?.();
    } catch (e) {}
  };

  return (
    <ConfirmDialog
      dialogContentProps={{
        title: "Confirm Delete User"
      }}
      confirmButtonProps={{
        text: "Delete",
        styles: dangerButtonStyles(theme),
        disabled: name !== fullName || isDeleting
      }}
      cancelButtonProps={{ text: "No" }}
      {...props}
      confirming={isDeleting}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          <Text>Please enter the user name to confirm:</Text>
          <Text>{fullName}</Text>
        </Stack>
        <TextField value={name} onChange={(e, value) => setName(value)} />
      </Stack>
      {error && (
        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
      )}
    </ConfirmDialog>
  );
};
