import "highlight.js/styles/tomorrow-night-blue.css";

import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  DefaultButton,
  IStackProps,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  SiteAvailableReport,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { useSiteReportQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { guid } from "@libs/common/guid";

interface Props {
  tenantId: guid;
  report?: SiteAvailableReport;
  component: SiteComponentType;
}

const hostStyles: IStackProps["styles"] = {
  root: {
    "& > pre": {
      flexGrow: 1,
      height: 1,

      code: {
        height: "100%",
        overflowY: "auto"
      }
    }
  }
};

export const Report = ({ tenantId, component, report }: Props) => {
  const query = useSiteReportQuery(tenantId, component, {
    enabled: true,
    report: report?.id || ""
  });

  const theme = useTheme();

  return report ? (
    <Stack
      verticalFill
      tokens={{ childrenGap: theme.spacing.s1 }}
      styles={{ root: { width: 1, flexGrow: 1 } }}
    >
      <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
        <Text variant="large">{report.name}</Text>

        <DefaultButton
          styles={{ root: { alignSelf: "baseline" } }}
          onClick={() => query.refetch()}
          disabled={query.isFetching}
        >
          {query.isIdle ? "Run" : "Re-run"}
        </DefaultButton>
        {query.isFetching && <Spinner />}
      </Stack>
      {!query.isIdle && (
        <QueryStateIndicator {...query}>
          {report => (
            <Stack styles={hostStyles} grow>
              <SyntaxHighlighter
                language="json"
                // eslint-disable-next-line react/forbid-component-props
                style={a11yDark}
                wrapLongLines={true}
                wrapLines={true}
              >
                {JSON.stringify(report, undefined, "    ")}
              </SyntaxHighlighter>
            </Stack>
          )}
        </QueryStateIndicator>
      )}
    </Stack>
  ) : null;
};
