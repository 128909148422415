import { BusinessUsersTable } from "./BusinessUsersTable";
import React, { useState } from "react";

import {
  CenteredBox,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { withPermissions } from "@components/withPermissions";
import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useBusinessUserSearch } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { BusinessUsersFilter } from "./BusinessUsersFilter";

const BusinessUsersBase: React.FC = () => {
  const theme = useTheme();
  const [filter, setFilter] = useState<BpIdUsersSearchArgs | undefined>(
    undefined
  );

  const { data, isFetching } = useBusinessUserSearch(filter);

  return (
    <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
      <BusinessUsersFilter
        onFilter={setFilter}
        onReset={() => setFilter(undefined)}
      />
      {data?.tooManyResults === true && (
        <MessageBar messageBarType={MessageBarType.error}>
          Your search returned too many results, please refine your search
          parameters.
        </MessageBar>
      )}
      <Stack verticalFill>
        {data?.records?.length || isFetching ? (
          <BusinessUsersTable
            enableShimmer={isFetching}
            items={data?.records || []}
          />
        ) : (
          <CenteredBox>
            <Text>No business users found</Text>
          </CenteredBox>
        )}
      </Stack>
    </Stack>
  );
};

export const BusinessUsers = withPermissions(
  BusinessUsersBase,
  [Permissions.PltBpidUserRead, Permissions.PltBpidUserWrite],
  "or"
);
