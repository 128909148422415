import React from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { FieldDeviceDeploymentRingDetail } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useFieldDeviceDeploymentRingDetail } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { RolloutDevicesAssignedTable } from "./RingDevicesAssignedTable";
import {
  RingDevicesAssignedTableFilter,
  RingDevicesAssignedTableFilterValues
} from "./RingDevicesAssignedTableFilter";

interface DevicesAssignedProps {
  deploymentRingId: string;
}

export const DevicesAssigned: React.FC<DevicesAssignedProps> = ({
  deploymentRingId
}) => {
  const theme = useTheme();

  const { data: devicesAssigned } = useFieldDeviceDeploymentRingDetail(
    undefined,
    deploymentRingId
  );

  return (
    <Stack
      tokens={{ childrenGap: theme.spacing.m }}
      styles={{ root: { width: "23%" } }}
    >
      {/* Devices section */}
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Text variant="xLarge">Devices Assigned</Text>
          <Stack.Item align="end">
            <Text
              styles={{
                root: {
                  background: theme.semanticColors.listItemBackgroundChecked,
                  width: "fit-content",
                  padding: 2
                }
              }}
            >
              Total Devices: {devicesAssigned?.length}{" "}
            </Text>
          </Stack.Item>
        </Stack>

        <RingDevicesAssignedTableFilter>
          {({ values }) => {
            return (
              <RolloutDevicesAssignedTable
                items={filterAndSortDevices(devicesAssigned ?? [], values)}
              />
            );
          }}
        </RingDevicesAssignedTableFilter>
      </Stack>
    </Stack>
  );
};

const filterAndSortDevices = (
  rolloutSoftwarePackageVersions: FieldDeviceDeploymentRingDetail[],
  filter: RingDevicesAssignedTableFilterValues
): FieldDeviceDeploymentRingDetail[] => {
  return rolloutSoftwarePackageVersions
    .filter(
      d =>
        filter.fieldDeviceId === "" ||
        d.fieldDeviceId
          .toLowerCase()
          .indexOf(filter.fieldDeviceId.toLowerCase()) >= 0
    )
    .sort(
      (
        a: FieldDeviceDeploymentRingDetail,
        b: FieldDeviceDeploymentRingDetail
      ) => {
        return a.id < b.id ? 1 : -1;
      }
    );
};
