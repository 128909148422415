import {
  CopyToClipboardButton,
  DetailsRow,
  IColumn,
  Text
} from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import {
  FieldDevice,
  FieldDeviceDetails
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface DeviceOverviewTableProps {
  items: OverviewTableItem[];
  error?: HttpError;
  isLoading?: boolean;
}

export interface OverviewTableItem {
  Name: string;
  Value: JSX.Element;
}

export const DeviceOverviewTable = ({
  items,
  error,
  isLoading
}: DeviceOverviewTableProps) => {
  const columns: IColumn[] = [
    {
      key: "Device Overview Item Name",
      name: "",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: OverviewTableItem) => <Text bold>{item.Name}</Text>
    },
    {
      key: "Device Overview Item Value",
      name: "",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: OverviewTableItem) => item.Value
    }
  ];

  return (
    <ShimmeredDetailsList
      enableShimmer={isLoading}
      errorMessage={error?.message}
      isSelectedOnFocus
      columns={columns}
      detailsListStyles={{
        root: {
          ".ms-DetailsHeader": {
            height: "15px" // This is used to remove height of table headers (as they are not needed)
          }
        },
        contentWrapper: { width: "50px" },
        focusZone: { width: "50px" },
        headerWrapper: { width: "50px" }
      }}
      styles={{ root: { width: "200px" } }}
      items={items}
      onRenderRow={row =>
        row ? <DetailsRow styles={{ cell: { height: 55 } }} {...row} /> : null
      }
    />
  );
};

export function getOverviewItems(
  deviceDetails: FieldDeviceDetails,
  fieldDevice: FieldDevice
) {
  return [
    { Name: "Computer Name", Value: <>{deviceDetails?.computerName}</> },
    {
      Name: "Field Device ID",
      Value: (
        <>
          {fieldDevice.id}{" "}
          <CopyToClipboardButton clipboardContent={fieldDevice.id} />
        </>
      )
    },
    {
      Name: "Cloud Connector ID",
      Value: (
        <>
          {fieldDevice.cloudConnectorId}{" "}
          <CopyToClipboardButton
            clipboardContent={fieldDevice.cloudConnectorId}
          />
        </>
      )
    },
    {
      Name: "Operating System",
      Value: <>{`${deviceDetails?.osName} (${deviceDetails?.osVersion})`}</>
    },
    {
      Name: "System Domain",
      Value: <>{deviceDetails?.systemDomain}</>
    },
    {
      Name: "Maintenance Script Version",
      Value: <>{deviceDetails?.maintenanceScriptVersion}</>
    },
    {
      Name: "Timezone",
      Value: <>{deviceDetails?.timezone}</>
    }
  ] as OverviewTableItem[];
}
